export function getChannelType(channel: string) {
	if (!channel) return "";
	if (channel.startsWith("private.")) {
		return "private";
	} else if (channel.startsWith("group.")) {
		return "class";
	} else if (channel.startsWith("chat-group.")) {
		return "group";
	} else if (channel.startsWith("broadcast.")) {
		return "broadcast";
	} else {
		return "unknown";
	}
}

export const getPathFromUrl = (url: string): string | undefined => {
	if (url === null || url === undefined) return undefined;
	const match = url.match(/\/([^?]+)/);
	return match?.[1];
};

export function timetokenTwoMode(timestamp: string) {
	const parse = Number.parseInt(timestamp);
	const waktu = parse / 1e4;
	const currentTime = new Date();
	const notificationTime = new Date(waktu);

	const timeDifference = currentTime.getTime() - notificationTime.getTime();
	const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

	if (hoursDifference < 24) {
		// Jika kurang dari 24 jam, tampilkan waktu
		const formattedTime = notificationTime.toLocaleTimeString("id-ID", {
			hour: "2-digit",
			minute: "2-digit",
		});
		return formattedTime;
	}
	if (hoursDifference >= 24) {
		// Jika lebih dari 24 jam, tampilkan tanggal
		const formattedDate = notificationTime.toLocaleDateString("id-ID", {
			day: "numeric",
			month: "short",
		});
		return formattedDate;
	}
}
